import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
// import { srConfig, email } from '@config';
import { srConfig } from '@config';

import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 20px;
  text-align: center;
  height: auto;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
    .hUNLBw .form_section input {
      width: 70vw !important ;
    }

    .form_section textarea {
      width: 70vw !important ;
    }
  }

  @media (max-width: 600px) {
    margin: 0 auto 50px;
    .form_section input {
      width: 70vw !important ;
    }

    .form_section textarea {
      width: 70vw !important;
    }
  }

  @media (max-width: 480px) {
    .form_section input {
      width: 70vw !important ;
    }

    .form_section textarea {
      width: 70vw !important;
    }
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

  .form_div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 50px;
  }
  .form_section div {
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
  }

  .form_section input {
    width: 50vw;
    height: 40px;
    border: 1px solid var(--green);
    border-radius: 5px;
    background-color: #0a192f;
    padding: 10px;
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
    color: var(--white);
    font-family: var(--font-mono);
  }

  .form_section textarea {
    width: 50vw;
    height: 100px;
    padding: 10px;
    border: 1px solid var(--green);
    border-radius: 5px;
    background-color: #0a192f;
    color: var(--white);
  }

  .form_section input[type='submit'] {
    width: 100px;
    height: 40px;
    margin-top: 20px;
    color: var(--green);
  }

  // .sttatic{
  //   margin-top: 50px;
  // }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">Contact</h2>
      <h2 className="title">Get In Touch</h2>
      <p>
        Although I’m not currently looking for any new opportunities, my inbox is always open.
        Whether you have a question or just want to say hi, I’ll try my best to get back to you!
      </p>
      {/* <a className="email-link" href={`mailto:${email}`}>
        Say Hello
      </a> */}
      {/* // make a contact form with input box */}
      <div className="form_div">
        <form
          className="form_section"
          action="https://formcarry.com/s/9NOEV2DoWlF"
          method="POST"
          acceptCharset="UTF-8">
          <div>
            <input type="text" name="Name" required placeholder="Name" />
          </div>
          <div>
            <input type="email" name="Email" required placeholder="Email" />
          </div>
          <div>
            <input type="text" name="Subject" required placeholder="Subject" />
          </div>
          <div>
            <textarea required name="Message" placeholder="Message" />
          </div>
          <input className="email-link" type="submit" value={'Submit'} />
        </form>

        {/* <div className='sttatic'>
          <p>
            <a href="https://www.linkedin.com/in/joshua-kim-b8a9b9a6/">
              <img src="https://img.icons8.com/color/48/000000/linkedin.png" alt="linkedin" />
            </a>

            <a href={`mailto:${email}`}>
             praj4936@gmail.com
            </a>

            
          </p>
        </div> */}
      </div>
    </StyledContactSection>
  );
};

export default Contact;
