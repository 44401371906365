import React, { useEffect, useRef } from 'react';
// import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
// import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';
import {
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiReact,
  SiRedux,
  SiMongodb,
  SiExpress,
  SiGithub,
  SiVercel,
  SiPostman,
} from 'react-icons/si';

import { GrNode } from 'react-icons/gr';

const StyledAboutSection = styled.section`
  max-width: 1000px;

    .inner {
      display: grid;
      // grid-template-columns: 3fr 2fr;
      // grid-gap: 50px;

      @media (max-width: 768px) {
        display: block;
      }
    }
`;
const StyledText = styled.div`
  .skill_div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // align-items: center;
    flex-wrap: wrap;
  }

  //     li {
  //       position: relative;
  //       margin-bottom: 10px;
  //       padding-left: 20px;
  //       font-family: var(--font-mono);
  //       font-size: var(--fz-xs);

  //       &:before {
  //         content: '▹';
  //         position: absolute;
  //         left: 0;
  //         color: var(--green);
  //         font-size: var(--fz-sm);
  //         line-height: 12px;
  //       }
  //     }
  //   }
  .individual_div h4 {
    padding-left: 20px;
    margin-bottom: 20px;
    // color: var(--green);
  }
  .individual_div div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
    left: 0;
    color: var(--green);
    font-size: var(--fz-xs);
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: var(--font-mono);
    font-size: var(--fz-xs);

    span {
      margin-top: 10px;
    }
  }

//   .individual_div div:hover {
//       span{
//         color: var(--green);
//       }
//     }

@media (max-width: 768px) {
  display: block;
}
`;

const Skill = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  //   const skills = ['AiFillHtml5', 'CSS', 'JavaScript (ES6+)', 'React', 'Node.js', 'WordPress'];

  return (
    <StyledAboutSection id="skill" ref={revealContainer}>
      <h2 className="numbered-heading">Skills</h2>

      <div className="inner">
        <StyledText>
          <div className="skill_div">
            <div className="individual_div">
              <h4>Front-end</h4>
              <div>
                <SiHtml5 />
                <span> HTML</span>
              </div>
              <div>
                <SiCss3 />
                <span> CSS</span>
              </div>
              <div>
                <SiJavascript />
                <span> JavaScript</span>
              </div>
              <div>
                <SiReact />
                <span> React</span>
              </div>
              <div>
                <SiRedux />
                <span> Redux</span>
              </div>
            </div>
            <div className="individual_div">
              <h4>Back-end</h4>
              <div>
                <GrNode />
                <span> NodeJs</span>
              </div>
              <div>
                <SiMongodb />
                <span> mongoDB</span>
              </div>
              <div>
                <SiExpress />
                <span> Express</span>
              </div>
              <div>
                <SiPostman />
                <span> Postman</span>
              </div>
            </div>

            <div className="individual_div">
              <h4>Programming Language</h4>
              <div>
                <SiJavascript />
                <span> JavaScript</span>
              </div>
            </div>

            <div className="individual_div">
              <h4>Hosting Platform</h4>
              <div>
                <SiGithub />
                <span> Github-Page</span>
              </div>
              <div>
                <SiVercel />
                <span>Vercel</span>
              </div>
            </div>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default Skill;
